import { template as template_07c9747f3a70431d8417e846dec29627 } from "@ember/template-compiler";
const FKLabel = template_07c9747f3a70431d8417e846dec29627(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
