import { template as template_45f0fd350d874059abfe3da85c1deadd } from "@ember/template-compiler";
const FKControlMenuContainer = template_45f0fd350d874059abfe3da85c1deadd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
